body {
  background-color: #edc58d;
  background-image: url("./assets/background.webp");
  background-repeat: repeat;
  background-size: 100% auto;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Mplus 1p", sans-serif;
  font-weight: 500;
}

h1 {
  font-weight: 900;
}

h2 {
  font-weight: 800;
}

h3 {
  font-weight: 700;
}

a {
  color: #000;
  text-decoration: none;
}

button {
  width: 100%;
}

a,
button {
  transition: all 0.3s ease;
  cursor: pointer;
}

a:hover,
button:hover {
  filter: brightness(0.9);
}

select {
  max-width: 320px;
  width: 100%;
  height: 48px;
  font-size: 24px;
}

img,
video {
  vertical-align: top;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.app {
  margin: 0 auto;
  padding: 0 16px;
  min-height: 100vh;
  max-width: 720px;
  text-align: center;
}

.gengas {
  display: grid;
  gap: 16px;
  margin: 0 auto;
}

.gengas div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 4px;
}

.row-2 {
  grid-template-columns: repeat(2, 1fr);
}

.cover-genga {
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.1), 2px 2px 2px rgba(0, 0, 0, 0.1),
    0px -1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.title-h2-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 209.47px;
  height: 60px;
  background-image: url("../src/assets/titile/h2-title.webp");
  background-position: center;
  background-size: cover;
}

.title-s,
.title-n {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-s {
  width: 133.125px;
  height: 60px;
  background-image: url("./assets/titile/special-title.webp");
  background-position: center;
  background-size: cover;
}

.title-n {
  width: 133.125px;
  height: 60px;
  background-image: url("./assets/titile/normal-title.webp");
  background-position: center;
  background-size: cover;
}

.video-player {
  width: 100%;
  height: 100%;
}
