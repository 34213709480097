.footer {
  background-color: #c9e900;
}

.footer-container {
  margin: 0 auto;
  padding: 32px;
  max-width: 720px;
  text-align: center;
}

.footer-link {
  color: #000;
  font-size: 14px;
  text-decoration: none;
}

.text-link :hover {
  border-bottom: solid #000 1px;
}

.w-100 {
  width: 100%;
}
