.yubi-button {
  position: relative;
  max-width: 320px;
  height: 52px;
  border: 1px solid black;
  border-bottom: 6px solid black;
  border-radius: 26px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-align: center;
  cursor: pointer;
  /* テキストを中央に配置 */
}

.yubi-button span {
  font-weight: 700;
}

.yubi-button.disabled {
  cursor: not-allowed;
}

.yubi-button img {
  position: absolute;
  /* padding 8px + margin-left 16px = 24px */
  top: 50%;
  right: 24px;
  /* 上下中央に配置 */
  width: 58px;
  height: 58px;
  transform: translateY(-50%);
}

.yubi-button-span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 上下、左右中央に配置 */
}

.disabled-button {
  width: 100%;
  border: none;
  background-color: #f0f0f0;
  color: #757373;
  filter: none;
  cursor: auto;
}

.disabled-button:hover {
  filter: none;
}
