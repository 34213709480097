.notfound-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 56px);
}

.notfound h2 {
  font-size: 48px;
  font-weight: 700;
}

.notfound p {
  font-size: 16px;
  font-weight: 500;
}
