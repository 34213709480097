.genga-container {
  position: relative;
  display: inline-block;
}

.s-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 101;
  width: 25%;
}

.cut_bukuro {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 102;
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.visible {
  opacity: 0;
  pointer-events: none;
}
