.fixed-bottom {
  position: fixed;
  bottom: 16px;
  z-index: 999;
  display: flex;
  justify-content: center;
  max-width: calc(100vw - 32px);
  width: 100%;
}

@media (min-width: 752px) {
  .fixed-bottom {
    max-width: 720px;
  }
}
