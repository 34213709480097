.base-button {
  max-width: 320px;
  height: 52px;
  border: 1px solid black;
  border-bottom: 6px solid black;
  border-radius: 26px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.base-button.disabled {
  cursor: not-allowed;
}

.disabled-button {
  width: 100%;
  border: none;
  background-color: #f0f0f0;
  color: #757373;
  filter: none;
  cursor: auto;
}

.disabled-button:hover {
  filter: none;
}
