.video-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.skip-button {
  position: absolute;
  bottom: 16px;
  left: 50%;
  max-width: 320px;
  height: 52px;
  border: none;
  border-bottom: 6px solid black;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  transform: translateX(-50%);
  cursor: pointer;
}
