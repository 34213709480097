.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  margin: 16px;
  padding: 32px;
  max-width: 360px;
  width: calc(100% - 64px);
  border-radius: 8px;
  background-color: white;
  text-align: center;
}

.cancel {
  cursor: pointer;
}
