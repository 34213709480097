.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.6);
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 16px;
  max-width: 720px;
  height: 24px;
}

.header-logo {
  flex-shrink: 0;
  width: 35%;
  height: auto;
}

.header-logo-link {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.header-logo img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.header-links {
  display: flex;
  justify-content: flex-end;
  width: 65%;
}

.header-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-link:not(:last-child) {
  margin-right: 8px;
}

.header-link img {
  margin-bottom: 4px;
  max-height: 28px;
  width: auto;
}

.header-link a {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  text-decoration: none;
}

.header-link p {
  font-size: 8px;
}

.header button {
  padding: 8px 20px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
